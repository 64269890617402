@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;600&family=Open+Sans:wght@300&display=swap");

ul,
li,
p,
button {
  font-family: "Inter", sans-serif;
  font-family: "Open Sans", sans-serif;
}

@font-face {
  font-family: "Faktum";
  src: url("../src/assets/font/faktum-test-bold.otf")
    format("opentype");
  /* You can also include other font formats here like woff and woff2 for better browser compatibility */
}

h1,
h2 {
  font-family: "Inter", sans-serif;
  font-weight: 800;
  font-size: 56px;
  line-height: 72px;
  color: #0e0b3d;
}

h3 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 36px;
  color: #0e0b3d;
}

h4 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.01em;
  text-align: left;
}

@media (max-width: 640px) {
  h1,
  h2 {
    font-size: 48px;
    line-height: 60px;
  }

  h3 {
    font-size: 32px;
  }

  h4 {
    font-size: 14px;
    line-height: 28px;
  }
}

@media (min-width: 641px) and (max-width: 768px) {
  h1,
  h2 {
    font-size: 52px;
    line-height: 68px;
  }

  h3 {
    font-size: 34px;
  }

  h4 {
    font-size: 15px;
    line-height: 30px;
  }
}

.login-text {
  font-family: "Inter";
  font-weight: 300;
  font-size: 28px;
  line-height: 72px;
  color: #0e0b3d;
}

/* Customize the scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #f5bf45;
}

::-webkit-scrollbar-thumb:hover {
  background: #e70111;
}
