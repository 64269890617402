.faq-container {
    background-color: #fff6e3;
    margin: 8px;
    padding: 32px;
    border-radius: 5px;
    width: 45%;
    background: linear-gradient(0deg, #fff6e3, #fff6e3),
      linear-gradient(0deg, #fff8e3, #fff8e3);
  }
  
  .faq-item {
    cursor: pointer;
    width: 100%;
    padding: 12px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 2px 3px 0px #0000000f;
  }
  
  .question {
    font-family: Inter;
    font-size: 16px;
    font-weight: 550;
    line-height: 32px;
    letter-spacing: 0.01em;
    text-align: left;
  }
  
  .faq-button {
    box-shadow: 0px 2px 5px 0px #0000000d;
    padding: 15px 36px;
    background-color: white;
    border-radius: 20px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 550;
    line-height: 19px;
    letter-spacing: 0.005em;
    text-align: center;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    .faq-container {
      width: 90%; /* Adjust the width for smaller screens */
      margin: 8px auto; /* Center the container horizontally */
    }
  
    .faq-button {
      width: 100%; /* Make the buttons occupy the full width */
    }
  }
  
  @media (max-width: 480px) {
    .faq-container {
      padding: 24px; /* Adjust the padding for smaller screens */
    }
  }
  