.contact-btn {
    background-color: #130A46;
    box-shadow: 0px 4px 6px 0px #0000002E;
    padding: 12px 30px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    border-radius: 20px;
}