.form-styles {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 18px 0px #00000014;
  padding: 36px;
  border-radius: 10px;
}

.input-box {
  border: 1px solid #00000005;
  color: bisque;
  margin: 12px 0px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #00000005;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)),
  linear-gradient(0deg, rgba(245, 191, 69, 0.1), rgba(245, 191, 69, 0.1));
  
}

