.welcome-background {
  background-image: url("../../../assets/images/Vector 48.svg");
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 55%;
}

.card-container {
  background: #f5f4f7;
  border: 1px solid #f5f4f7;
  box-shadow: 0px 1px 8px 0px #00000012;
  z-index: -10;
}

.analytics-background {
  background-color: #f5f4f7;
  z-index: -20;
}

.team-card {
  border: 1px solid #f5f4f7;
  background-color: #fffbf2;
  box-shadow: 0px 1px 8px 0px #00000012;
}

@media (min-width: 641px) and (max-width: 768px) {
  .welcome-background {
    background-size: 75%; /* Adjust the background size for medium-sized screens */
  }
}

@media (max-width: 640px) {
  .welcome-background {
    background-size: 100%; /* Adjust the background size for smaller screens */
  }
}