.contact-background {
  background-image: url("../../assets/images/Ellipse\ 27.png");
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: 25%;
}

.form-background {
  border: 1px solid #f5f4f7;
  box-shadow: 0px 1px 8px 0px #00000012;
}
