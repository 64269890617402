.testimonial-card {
  border: 1px solid #f5f4f7;
  background-color: #fffbf2;
  box-shadow: 0px 1px 8px 0px #00000012;
}

.testimonial-text {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #373a2f;
}
