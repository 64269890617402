.product-card {
  box-shadow: 0px 1px 8px 0px #00000012;
  border: 1px solid #f5f4f7;
  background: #fffbf2;
}

.product-name {
  font-family: Inter;
  font-size: 16px;
  font-weight: 550;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
}

.product-price {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.01em;
  text-align: left;
}
