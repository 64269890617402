.main-container {
  padding: 32px 16px 32px 16px;
  border-radius: 8px;
  gap: 21px;
  background: #ffffff;
  box-shadow: 0px 2px 26px 0px #0000000f;
  width: 25%;
}

.product-heading {
  font-family: Plus Jakarta Sans;
  font-size: 21px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 0.01em;
  text-align: left;
}
